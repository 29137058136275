import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import { formatMoney, formatNumber } from "@/filters/index";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

import selectMeasuringType from "@/components/inventory/select-measuring-type";

export default {
    mixins: [form, drawer],
    data() {
        return {
            mode: true,
            product_id: null,
            batchDialog: false,
            selectedProduct: {},
            loadingProducts: false,
            loadingData: false,
            dialogProductsList: false,
            selectedProducts: [],
            dealProductsList: [],
        }
    },
    components: {
        selectMeasuringType,
    },
    watch: {
        'pagination.page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        'pagination.per_page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            containerProducts: "packageForProductCirculations/products",
            authUser: "auth/user",
            rules: "transferingCargo/rules",
            model: "transferingCargo/model",
            columns: "dealProducts/columns",
            pagination: "transferingCargo/cargo_pagination", 
            allPackagesAndProducts: 'transferingCargo/incoming_transfer_cargo'
        }),
        
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
                
            });
            return _.round(parseFloat(total) ,2);
        },
    },
    methods:
    {
        ...mapActions({
            save: "transferingCargo/store",
            showContainerItems: "packageForProductCirculations/showProducts",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            updatePagination: "transferingCargo/updateCargoPagination",
            getAllPackageAndProductsToSend: 'transferingCargo/incomingTransferCargo',
        }),
        handleSelectionChange(selected) {
            this.selectedProducts = selected;
        },
        
        async afterOpen(){
            await this.updatePagination({ key: "page", value: 1 });
            if (_.isFunction(this.debouncedFetchData)) {
                this.debouncedFetchData();
            }
        },
        showDirectionProducts(){
            const query = {...this.payload_for_prod_list, type: this.type, ...this.pagination}; 
            this.loadingData = true;
            this.getAllPackageAndProductsToSend(query)
                .then(res => {
                    this.dealProductsList = JSON.parse(JSON.stringify(this.allPackagesAndProducts));
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                });
        },
        afterLeave(){
            this.product_id = null;
            this.dealProductsList = [];
        },
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },
        showContainerProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showContainerItems(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        async updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                       
                        this.$set(el, 'incoming_quantity', parseFloat(total_quantity));
                    }
                });
            }
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        checkValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', parseFloat(0))
            }
        },
        showInputCheck(row){
            if(row.type === 'product' && (row.measuring_type_id === 2) && (!row.batches || row.batches.length <= 1)) {
                return true;
            }
            return false;
        },
        updatePoundAndKG(row, type){
            if(type === 'weight_in_kg'){
                this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 6));
            }else{
                this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 6));
            }
        },
        updateWeight(row){
            if(row.measuring_type_id && row.measuring_type_id === 2){
                let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
                this.$set(row, 'weight', _.round(parseFloat(size_weight), 6));
            }else{
                let current_row = this.dealProductsList.find(el => el.id === row.id);
                let old_weight = JSON.parse(JSON.stringify(current_row.weight));
                this.$set(row, 'weight',  _.round(parseFloat(old_weight), 6));
            }
        },
        insufficiant_prod_message(){
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        submit(close = true) {
            this.$emit('mergeToProductList', this.selectedProducts);
            this.selectedProducts = [];
            this.dealProductsList = [];
            this.close();
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },


    }
}
