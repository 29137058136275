<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
            <el-col :span="12">
                <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.transferring_cargo") }} </div>
                <div class="block-sarche">
                    <div class="header__search">
                    
                    <crm-input
                            :size="'small'"
                            :class="mode ? 'input__day' : 'input__night'"
                            :className="'w100'"
                            v-model="filterForm.search"
                            :icon="'el-icon-search'"
                        ></crm-input>
                    </div>
                </div>
                </div>
            </el-col>

            <el-col :span="12" class="flex-style text-right">
                <div class="w-100 text-right">
                    <div class="d-flex">
                        <el-button @click="drawerRecieve = true" class="mr-2" size="mini">{{$t('message.goods_arrival')}}</el-button>
                        <el-button @click="drawerSend = true" class="mr-2" size="mini">{{$t('message.goods_dispatch')}}</el-button>
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerSend = true"
                            :columns="columns"
                            :allowCreate="false"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </div>
                </div>
                    
            </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart custom__scroll">
            <table 
            class="table-my-code table-bordered"
            :class="mode ? 'table__myday' : 'table__mynight'"
            v-loading="loadingData"
            >
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>

                    <th v-if="columns.user_id.show">
                        {{ columns.user_id.title }}
                    </th>

                    <th v-if="columns.from_filial_id.show">
                        {{ columns.from_filial_id.title }}
                    </th>

                    <th v-if="columns.to_filial_id.show">
                        {{ columns.to_filial_id.title }}
                    </th>

                    <th v-if="columns.comment.show">
                        {{ columns.comment.title }}
                    </th>

                    <th v-if="columns.created_at.show">
                        {{ columns.created_at.title }}
                    </th>

                    <th v-if="columns.updated_at.show">
                        {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                <th v-if="columns.id.show">
                    <el-input
                        clearable
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.id"
                        :placeholder="columns.id.title"
                        class="id_input"
                    ></el-input>
                </th>

                <th v-if="columns.user_id.show">
                        <select-users
                            :placeholder="$t('message.responsible')"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.user_id"
                        ></select-users>
                </th>

                <th v-if="columns.from_filial_id.show">
                        <select-from-filial
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.from_filial_id.title"
                            v-model="filterForm.from_filial_id"
                        >
                        </select-from-filial>
                </th>

                <th v-if="columns.to_filial_id.show">
                        <select-to-filial
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.to_filial_id.title"
                            v-model="filterForm.to_filial_id"
                        >
                        </select-to-filial>
                </th>

                <th v-if="columns.comment.show">
                </th>

                <th v-if="columns.created_at.show">
                    <crm-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                    ></crm-date-picker>
                </th>

                <th v-if="columns.updated_at.show">
                    <crm-date-picker
                        :placeholder="columns.updated_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.updated_at"
                    ></crm-date-picker>
                </th>

                <th
                    class="settinW"
                    v-if="columns.settings.show"
                ></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="transfer in list" :key="transfer.id" class="cursor-pointer">

                    <td v-if="columns.id.show">
                        {{ transfer.id }}
                    </td>

                    <td v-if="columns.user_id.show">
                        {{ transfer.user ? transfer.user.name : ''}}
                    </td>

                    <td v-if="columns.from_filial_id.show">
                        {{ transfer.from_filial ? transfer.from_filial.name : ''}}
                    </td>

                    <td v-if="columns.to_filial_id.show">
                        {{ transfer.to_filial ? transfer.to_filial.name : ''}}
                    </td>

                    <td v-if="columns.comment.show">
                        {{ transfer.comment }}
                    </td>
                
                    <td v-if="columns.created_at.show">
                        {{ transfer.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                        {{ transfer.updated_at }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <el-button @click="showProducts(transfer)" type="primary" size="mini" icon="el-icon-view"></el-button>
                    </td>
                </tr>
            </transition-group>
            </table>
            <div class="my___pagination">
            <crm-pagination
                @c-change="updatePagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
                :pagination="pagination"
            ></crm-pagination>
            <!-- <Pagination /> -->
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false" 
                class="bg-se"
                size="100%" 
                :visible.sync="drawerSend"
                ref="drawerSend"
                @opened="drawerOpened('drawerSendChild')"
                @closed="drawerClosed('drawerSendChild')"
            >
                <crm-send-cargo
                    ref="drawerSendChild"
                    drawer="drawerSend"
                    > 
                </crm-send-cargo>
            </el-drawer>

            <el-drawer
                :with-header="false" 
                class="bg-se"
                size="100%" 
                :visible.sync="drawerRecieve"
                ref="drawerRecieve"
                @opened="drawerOpened('drawerRecieveChild')"
                @closed="drawerClosed('drawerRecieveChild')"
            >
                <crm-recieve-cargo
                    ref="drawerRecieveChild"
                    drawer="drawerRecieve"
                    > 
                </crm-recieve-cargo>
            </el-drawer>

            <el-drawer
                :with-header="false" 
                class="bg-se"
                size="100%" 
                :visible.sync="drawerShow"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                @closed="drawerClosed('drawerShowChild')"
            >
                <show-transfer
                    :selectedItem="selectedItem"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    > 
                </show-transfer>
            </el-drawer>

        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmSendCargo from "./components/crm-send-cargo";
import CrmRecieveCargo from "./components/crm-recieve-cargo";
import showTransfer from "./components/show-transfer";
import { mapGetters, mapActions } from "vuex";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import SelectUsers from '@/components/inventory/select-users';

export default {
    name: "transferingCargo",
    mixins: [list],
    components: {
        Pagination,
        CrmSendCargo,
        showTransfer,
        CrmRecieveCargo,
        selectFromFilial,
        selectToFilial,
        SelectUsers,
    },

    data() {
        return {
            drawerRecieve: false,
            drawerSend: false,
            drawerShow: false,
        };
    },

    computed: {
        ...mapGetters({
            list: "transferingCargo/list",
            columns: "transferingCargo/columns",
            pagination: "transferingCargo/pagination",            
            filter: "transferingCargo/filter",
            sort: "transferingCargo/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "transferingCargo/index",
            setPagination: "transferingCargo/setPagination",
            updateSort: "transferingCargo/updateSort",
            updateFilter: "transferingCargo/updateFilter",
            updateColumn: "transferingCargo/updateColumn",
            updatePagination: "transferingCargo/updatePagination",
            show: "transferingCargo/show",
            empty: "transferingCargo/empty",
            delete: "transferingCargo/destroy",
            refreshData: "transferingCargo/refreshData",
        }),

        showProducts(transfer){
            this.drawerShow = true;
            this.selectedItem = transfer;
        }
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('transferingCargo/EMPTY_LIST');
        next()
    },
};
</script>

