import _ from 'lodash';
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { mapGetters, mapActions } from "vuex";
import { formatMoney, formatNumber } from "@/filters/index";

import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import selectMeasuringType from "@/components/inventory/select-measuring-type";
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";

export default {
    mixins: [form, drawer],
    data() {
        return {
            mode: true,
            barcode_search: '',
            selectedProd: {},
            product_id: null,
            dealProductsList: [],
            batchDialog: false,
            selectedProduct: {},
            loadingProducts: false,
            drawerProductList: false,
            dialogProductsList: false,
            dialogParcelProductsList: false,
        }
    },
    components: {
        selectFromFilial,
        selectToFilial,
        selectClient,
        selectMeasuringType,
        parcelProductsList
    },
    watch: {
        form: {
            handler: function(newVal, oldVal) {
                // this.dealProductsList = [];
            },
            deep: true,
            immediate: true
        },
    },
    
    computed: {
        ...mapGetters({
            containerProducts: "packageForProductCirculations/products",
            authUser: "auth/user",
            rules: "transferingCargo/rules",
            model: "transferingCargo/model",
            columns: "dealProducts/columns",
        }), 
    }, 
    methods:{
        ...mapActions({
            showProducts: "packageForProductCirculations/showProducts",
            scanIncomingTransferCargo: "transferingCargo/scanIncomingTransferCargo",
        }),
        searchProductDebounce: _.debounce(
            function(val) {
                if(val){
                    this.loadingData = true;
                    this.scanIncomingTransferCargo({barcode: val, to_filial_id: this.form.to_filial_id, type: this.transfer_type})
                        .then(res => {
                            this.loadingData = false;
                            let scan_result = res.data.result
                            if(scan_result.data.id){
                                if(!this.form.to_filial_id){
                                    this.form.to_filial_id = scan_result.data.to_filial_id
                                }
                                if(!this.form.from_filial_id){
                                    this.form.from_filial_id = scan_result.data.from_filial_id
                                }
                                this.get_selected_product({product: scan_result.data, barcode: scan_result.data.barcode, type: scan_result.data.type});
                            }
                            this.barcode_search = '';
                            
                        }).catch(err => {
                            this.loadingData = false;
                        })
                }
                
            }, 200),
        

        showDirectionProducts(){
            this.drawerProductList = true;
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                        this.$set(el, 'incoming_quantity', parseFloat(total_quantity));
                    }
                });
            }
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight);
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        checkValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', parseFloat(0))
            }
        },
        showInputCheck(row){
            if(row.type === 'product' && (row.measuring_type_id === 2) && (!row.batches || row.batches.length <= 1)) {
                return true;
            }
            return false;
        },
        updatePoundAndKG(row, type){
            if(type === 'weight_in_kg'){
                this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 3));
            }else{
                this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 3));
            }
        },
        
        insufficiant_prod_message(){
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        updateWeight(row, type){
            if(type){
                if(type === 'width'){
                    this.$set(row, 'width_on_inches',  _.round(parseFloat(row.width) * this.$sm_to_inch, 2));
                }
                if(type === 'width_on_inches'){
                    this.$set(row, 'width',  _.round(parseFloat(row.width_on_inches) / this.$sm_to_inch, 2));
                }

                if(type === 'height'){
                    this.$set(row, 'height_on_inches',  _.round(parseFloat(row.height) * this.$sm_to_inch, 2));
                }
                if(type === 'height_on_inches'){
                    this.$set(row, 'height',  _.round(parseFloat(row.height_on_inches) / this.$sm_to_inch, 2));
                }

                if(type === 'length'){
                    this.$set(row, 'length_on_inches',  _.round(parseFloat(row.length) * this.$sm_to_inch, 2));
                }
                if(type === 'length_on_inches'){
                    this.$set(row, 'length',  _.round(parseFloat(row.length_on_inches) / this.$sm_to_inch, 2));
                }
            }

            if(row.measuring_type_id && row.measuring_type_id === 2){
                let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
                this.$set(row, 'weight', _.round(parseFloat(size_weight), 3));
            }else{
                let current_row = this.dealProductsList.find(el => el.id === row.id);
                let old_weight = JSON.parse(JSON.stringify(current_row.weight));
                this.$set(row, 'weight',  _.round(parseFloat(old_weight), 3));
            }
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
            
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },
        removeItem(index){
            this.dealProductsList.splice(index, 1);
        },
        afterLeave(){
            this.product_id = null;
            this.dealProductsList = [];
        },
        
        showContainerProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.reloadList = true;
        },
    }
}
