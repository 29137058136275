<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.filial_product_list') }}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <div class="gc-card__body px-3 py-4" v-loading="loadingData">
            <div class="app-form__group mb-0">
                <div class="  gc-card rounded-sm mb-4 p-relative mr-3 px-3 py-3">
                    <el-table 
                        header-row-class-name="header__class"
                        row-class-name="row__class"
                        :data="dealProductsList" 
                        show-summary 
                        :summary-method="getSummaries" 
                        @selection-change="handleSelectionChange"
                        border stripe 
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column :label="$t('message.deal')" width="90">
                            <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.reciever')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.reciever">
                                    {{'ID: ' + scope.row.reciever.custom_id }} <br> {{ scope.row.reciever.name}} 
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.name')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                <span v-show="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.barcode')">
                            <template slot-scope="scope"> 
                                <span>{{ scope.row.barcode }} </span>    
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.quantity_y')">
                            <template slot-scope="scope"> 
                                <span>{{ scope.row.remainder }} </span>    
                                <span v-if="!scope.row.batches"> 1 </span>    
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.current_quantity')">
                            <template slot-scope="scope">
                                <div v-show="!scope.row.totalQuantity">
                                    <el-input
                                        :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                        :min="0"
                                        type="number"
                                        size="mini"
                                        @input="checkValue(scope.row)"
                                        v-model="scope.row.incoming_quantity"
                                    ></el-input>
                                </div>
                                <span v-show="scope.row.totalQuantity">
                                    {{scope.row.totalQuantity}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.measuring_type.title" v-if="columns.measuring_type.show">
                            <template slot-scope="scope">
                                <select-measuring-type
                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <= 1)"
                                :size="'mini'"
                                :placeholder="columns.measuring_type.title"
                                :id="scope.row.measuring_type_id"
                                v-model="scope.row.measuring_type_id"
                                @input="updateWeight(scope.row)"
                                ></select-measuring-type>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.weight.title">
                            <template slot-scope="scope">
                                <el-input 
                                    v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                    :min="0" 
                                    :disabled="showInputCheck(scope.row)"
                                    @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                    type="number" 
                                    size="mini" 
                                    v-model="scope.row.weight"
                                ></el-input>
                                <span v-show="scope.row.type === 'package'"> {{ scope.row.total_weight }} </span>
                                <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> {{ showWeight(scope.row) }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.weight_in_pounds.title">
                            <template slot-scope="scope">
                                <el-input 
                                    v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                    :min="0" 
                                    @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                    type="number" 
                                    size="mini" 
                                    v-model="scope.row.weight_in_pounds"
                                ></el-input>
                                <span v-show="scope.row.type === 'package'"> {{ parseFloat(scope.row.total_weight * $kg_to_pound).toFixed(6) }} </span>
                                <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> {{ parseFloat((showWeight(scope.row) * $kg_to_pound)).toFixed(6) }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                    v-show="showInputCheck(scope.row)"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeight(scope.row)"
                                    v-model="scope.row.width"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                    v-show="showInputCheck(scope.row)"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeight(scope.row)"
                                    v-model="scope.row.height"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                v-show="showInputCheck(scope.row)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeight(scope.row)"
                                v-model="scope.row.length"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('message.comment')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>

                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('message.update')" width="85">
                            <template slot-scope="scope"> 
                                <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                    @click="showAndUpdateBatches(scope.row)" 
                                    type="primary" icon="el-icon-edit" circle>
                                </el-button> 
                                <el-button v-if="scope.row.type == 'package'"
                                    @click="showContainerProducts(scope.row)" 
                                    type="primary" icon="el-icon-view" circle>
                                </el-button> 
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="my___pagination">
                        <crm-pagination
                            @c-change="updatePagination"
                            :class="mode ? 'pagination__day' : 'pagination__night'"
                            :pagination="pagination"
                        ></crm-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- Product partiyalar bo'yicha -->
        <el-dialog 
            :append-to-body="true"
            width="80%"
            :title="$t('message.product_inventorization')" 
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches">
                <el-table :data="selectedProduct.batches" border stripe>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                   <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column> 
                   <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.quantity_y')">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.measuring_type.title" v-if="columns.measuring_type.show">
                        <template slot-scope="scope">
                            <select-measuring-type
                            :size="'mini'"
                            :placeholder="columns.measuring_type.title"
                            :id="scope.row.measuring_type_id"
                            v-model="scope.row.measuring_type_id"
                            @input="updateWeight(scope.row)"
                            ></select-measuring-type>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title">
                        <template slot-scope="scope">
                            <el-input 
                                :min="0" 
                                :disabled="scope.row.measuring_type_id == 2"
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.weight"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight_in_pounds.title">
                        <template slot-scope="scope">
                            <el-input 
                                v-show="!(scope.row.measuring_type_id === 2)"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                v-model="scope.row.weight_in_pounds"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                                :disabled="!(scope.row.measuring_type_id === 2)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeight(scope.row)"
                                v-model="scope.row.width"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                                :disabled="!(scope.row.measuring_type_id === 2)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeight(scope.row)"
                                v-model="scope.row.height"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeight(scope.row)"
                            v-model="scope.row.length"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.comment')">
                        <template slot-scope="scope"> {{ scope.row.comment }} </template>
                    </el-table-column>
                </el-table>

                <div class="mt-4 cargo__right">
                    <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- Upakovka productlari -->
        <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
            <div v-loading="loadingProducts">
                <el-table :data="containerProducts">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                    <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import transfer_cargo_list_mix from "@/utils/mixins/views/transfer_cargo_list_mix";

export default {
    mixins: [transfer_cargo_list_mix],
    props: {
      payload_for_prod_list: {
        default: {}
      },
    },
    data: () => ({
        type: 'sending',
    }),
    computed: {
        ...mapGetters({
            // allPackagesAndProducts: 'transferingCargo/incoming_transfer_cargo'
        }),
    },
    created(){
        this.debouncedFetchData = _.debounce(this.showDirectionProducts, 500);
    },
    methods: {
        ...mapActions({
            // getAllPackageAndProductsToSend: 'transferingCargo/incomingTransferCargo',
        }),
    },
    
}
</script>

<style>

</style>
 